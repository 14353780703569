.project-card {
  border: 1px solid rgb(69, 70, 78);
  border-radius: 12px;
  .image-container {
    display: flex;
    justify-content: center;
    border-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: var(--blings_that_gray);
    position: relative;
    .project-card-thumb {
      display: block; // Ensure the image fills the container
      width: 100%; // Ensure the image is fully wide
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 12px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #d9d9d9;
      font-size: 14px;
      opacity: 0;
      transition: opacity 0.5s;
      &:hover {
        opacity: 1;
      }
    }
  }

  .ant-card {
    background-color: rgba(0, 0, 0, 0);

    .ant-card-meta {
      color: rgba(204, 204, 204, 1);

      .ant-card-meta-title {
        font-weight: 500;
        margin-bottom: 0;
        display: flex;
        width: 100%;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        justify-content: space-between;
        font-size: 14px;
      }

      .ant-card-meta-description {
      }
    }

    .ant-card-cover {
    }

    .ant-card-cover img {
      border-radius: 12px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      height: 12rem;
      object-fit: contain;
    }
  }

  .project-card-thumb {
    background-color: rgb(38, 38, 38);
  }
}
