@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.ResetSceneButton {
  background-color: #1e1e1e;
  padding: 9px 12px;
  border-radius: 8px;
  border-width: 0;
  position: absolute;
  margin: 1rem;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--ant-tag-default-color);
  z-index: 100;
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  &.slide-in {
    animation: slideIn 0.5s forwards;
  }
  .back-icon {
    margin: 12.5px 0 12.5px 0;
  }
  .text {
    font-size: 12px;
    margin: 12px;
  }
}
.ResetSceneButton:hover {
  background-color: #1e1e1e !important;
  color: white !important;
}
