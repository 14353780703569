$header-height: 3rem;
$margin-around-main-components: 0.5rem;
$border-radius-main-components: 10px;

.ant-layout {
  height: 100%;
}

.playground-top-bar {
  height: $header-height;
  margin: $margin-around-main-components;
  margin-bottom: 0;
  z-index: 110;
  border-radius: $border-radius-main-components;
}

.playground-left-sidebar {
  border-radius: 12px;

  .main-connectors {
    gap: 0.5rem;
    width: 100%;
    display: flex;

    #add-connectors-list {
      width: 10%;
    }

    .connectors-list {
      width: 90%;
    }
  }

  //overflow: hidden;
}

.NoProjectSelectedScreen {
  & > .ant-space-item {
    height: 90%;
  }
}

$SchemaEditorsWidth: 21rem;
$SchemaEditorsWidthMin: 16rem;
$SchemaEditorsWidthMax: 30vw;

.playground-left-sidebar {
  &.ant-layout-sider-zero-width {
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
  }

  .ant-layout-sider-zero-width-trigger {
    margin-right: 1rem;
    top: 8px;
    position: absolute;
    right: -35px;
  }
}

.playground-left-sidebar.ant-layout-sider-collapsed {
  .ant-layout-sider-zero-width-trigger {
    margin-right: 2rem;
  }
}

.ant-layout-sider-children {
  padding: 7px;
}

.VidContainerWrapper {
  &.schemaDrawerOpen {
    margin-right: #{"min(#{$SchemaEditorsWidthMax}, #{$SchemaEditorsWidth})"};
  }
}
.schemaDrawerOpen {
  margin-right: #{"min(#{$SchemaEditorsWidthMax}, #{$SchemaEditorsWidth})"};
}
.SchemaEditors {
  position: absolute;
  top: 8px;
  right: $margin-around-main-components;
  width: $SchemaEditorsWidth;
  min-width: $SchemaEditorsWidthMin;
  max-width: $SchemaEditorsWidthMax;
  z-index: 20;
  max-height: calc(100% - 16px);
  overflow: hidden;
  border-radius: 12px;
  .schema-drawer-button {
    font-weight: 400;
    padding: 0.5rem 0.8rem;
    &:hover {
      color: white;
    }
    .btn-text-child {
      margin-left: 0.3rem;
    }
  }
  &.opened {
    .schema-drawer-button {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    box-shadow: 0 0 0 1.8px #1a1a1a;
  }
  &:not(.opened) {
    height: 32px;
    box-shadow: 0 0 0 1.8px #1a1a1a;
    border-radius: 12px;
  }
  .SchemaEditors-open-button {
    margin-top: 0;
    width: 100%;

    button {
      color: var(--ant-color-text);
      display: flex;
      justify-content: flex-start;
      justify-content: center;
      align-items: center;
      background-color: var(--blings_that_gray);
      border-color: transparent;
      &:hover {
        background-color: var(--blings_that_gray) !important;
        border: none;
        --ant-button-default-hover-color: var(--ant-color-text);
      }
      width: 100%;
      .InfoTooltip {
        margin-left: auto;
        margin-right: 0.1rem;
      }
    }
  }

  .tabs {
    height: 100%;
    //height: calc(100vh - #{$header-height});
  }
}
