.DynamicValueBtn-tooltip {
  .ant-tooltip-arrow {
    display: none;
  }
}

.ModForm {
  //
  input,
  select,
  .ant-select .ant-select-selector,
  .ant-radio-button-wrapper,
  textarea,
  .ant-input-group-addon,
  .ant-input-affix-wrapper {
    // font-size: 0.8rem;
  }

  // font-size: 0.8rem;

  .breakpoint-input {
    width: 4rem;
  }

  .DynamicValueModComp {
    width: 100%;

    .ant-tabs-nav {
      // flex-flow: row-reverse;
      margin: 0 0 0.5rem 0;
      width: 290px;

      &::before {
        border: transparent;
      }

      .ant-tabs-nav-list {
        width: 372px;
      }
    }

    .ant-tabs-tab {
      // display: block; /* centers text inside tabs */
      flex: 1;
      text-align: center;
      width: 100%;
      margin: 0;
      justify-content: center;
    }

    .ant-tabs-nav > div:nth-of-type(1) {
      // display: unset;
      // width: 100%;
    }
  }

  &.text {
    .ant-tabs-nav-list {
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          div {
            color: var(--blings_connectors_text) !important;
          }
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: var(--blings_connectors_text);
    }
  }

  &.color,
  &.themeColor {
    .ant-tabs-nav-list {
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          div {
            color: var(--blings_connectors_color) !important;
          }
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: var(--blings_connectors_color);
    }
  }

  &.video,
  &.audio,
  &.asset {
    .ant-tabs-nav-list {
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          div {
            color: var(--blings_connectors_mediaAsset) !important;
          }
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: var(--blings_connectors_mediaAsset);
    }
  }

  &.advancedConfig,
  &.transitionTiming,
  &.custom,
  &.transform,
  &.onPlayerEvent {
    .ant-tabs-nav-list {
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          div {
            color: var(--blings_connectors_advanced) !important;
          }
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: var(--blings_connectors_advanced);
    }
  }

  &.interactiveOpen,
  &.interactiveGoto,
  &.interactiveJSExpression,
  &.interactiveConcat {
    .ant-tabs-nav-list {
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          div {
            color: var(--blings_connectors_interactive) !important;
          }
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: var(--blings_connectors_interactive);
    }
  }

  &.hideLayer,
  &.countdown,
  &.interactiveInput,
  &.interactiveForm {
    .ant-tabs-nav-list {
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          div {
            color: var(----blings_connectors_utils) !important;
          }
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: var(--blings_connectors_utils);
    }
  }

  .transform-change-type-radio-group {
    display: flex;
    margin: 0.5rem 0;
    width: 100%;

    .ant-radio-button-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.ant-radio-button-wrapper-checked {
      color: var(--blings_teal);
    }
  }

  .dynamic-value-tab-selector {
    display: flex;
    margin: 0.5rem 0;
    width: 100%;

    .ant-radio-button-wrapper {
      &::before {
        background-color: #4f4f4f;
      }

      &:hover {
        border-color: #4f4f4f;
        color: white;
      }

      flex: 1;
      //   // font-size: 0.75rem;
      //   height: 1.8rem;
      border-color: #4f4f4f;
      min-width: 1.8rem;
      display: flex;
      //   flex-direction: column;
      align-items: center;
      justify-content: center;

      //   //last child has no flex. Expression connector
      &:last-child {
        flex: none;
      }

      &.ant-radio-button-wrapper-checked {
        color: var(--blings_teal);
      }

      .ant-radio-button-wrapper-checked {
        // background-color: #8c8c8c;
      }

      // &:not(.ant-radio-button-wrapper-checked) {
      //   background-color: var(--blings_teal_80);
      //   &:hover {
      //     background: var(--blings_teal_60);
      //   }
      // }
      //   // If the button is disabled

      padding: 0 0.3rem;
      outline: none;

      &:focus {
        border: none;
      }

      &::before {
        outline: none;
        background: none;

        &:focus {
          border: none;
        }

        .text-icon {
          display: flex;
        }
      }
    }

    .ant-radio-button-wrapper-disabled {
      background-color: var(--disabled-color) !important;
    }
  }
}
