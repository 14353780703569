.App .blings-btn-success {
  color: var(--blings_primary);
  border-color: var(--blings_primary);
}
.ant-btn-primary {
  &.secondary {
    background-color: transparent;
    border: 1px solid #4f4f4f;
    color: var(--ant-color-text);
    &:disabled {
      border: 1px solid #4f4f4f;
      background-color: #363636;
      color: #5c5c5c;
    }
    &:hover:not(:disabled) {
      border: 1px solid #4f4f4f;
      --ant-color-primary-hover: #4f4f4f;
      color: white;
    }
  }
}
