#root {
  .playground-top-bar {
    padding-left: 1rem;
    padding-right: 1rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: sticky;
    top: 0;
    z-index: 1;
    width: auto;
    display: flex;
    align-items: center;
    background: #1a1a1a;

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 11rem;
    }

    .center {
      .ConnectToProject {
        .ant-typography {
          margin-bottom: 0;
          margin-right: 0.4rem;
          // This should only be show on 1 line
          white-space: nowrap;
        }
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      white-space: nowrap;

      @media (max-width: 650px) {
        display: none;
      }
    }

    .right {
      margin-left: auto;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 1rem;

      .PublishStatus {
        color: var(--blings_gray) !important;
        line-height: 1rem;

        p {
          text-overflow: ellipsis;
          word-break: break-all;
          overflow: hidden;
          // Add after the first line
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          // Set this to not have width
          width: fit-content;

          // If smaller than 750px, don't show
          @media (max-width: 750px) {
            display: none;
          }
        }
      }

      .PublishButtons {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        min-width: fit-content;
        .view-published-icon {
          cursor: pointer;
          display: flex;
        }
        a {
          color: var(--ant-color-text);
        }

        text-decoration: underline;

        a:hover {
          color: white;
        }

        @media (max-width: 610px) {
          display: none;
        }
      }

      .BurgerOverlayMenu {
        padding-bottom: 1rem;

        .ant-btn-primary {
          width: 100%;
        }

        &.ant-dropdown-menu {
          &,
          .ant-dropdown-menu-item {
            background: var(--blings_purple);
            color: #b6b6b6;
          }

          .ant-dropdown-menu-item:hover {
            color: white;

            //background: var(--blings_purple);
            .ant-dropdown-menu-item {
            }
          }
        }
      }
    }

    /* .ant-space-item:last-of-type {
    margin-left: auto;
  } */

    .burger-dropdown {
      cursor: pointer;
    }

    .tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      gap: 1rem;
      margin: auto;
      .ant-tabs-nav {
        margin-right: 0;
        .ant-tabs-nav-wrap {
          background: #262626 !important;
        }
        .ant-tabs-tab {
          padding-bottom: 5px;
          background: #262626 !important;
          border-right: 1px solid white !important;
        }
        .ant-tabs-ink-bar {
          background-color: white;
        }
        .ant-tabs-tab-btn {
          padding: 10px 8px 5px 8px;
          display: flex;
          color: #6e7079 !important;
          background: #262626 !important;

          .ant-tabs-tab-icon {
            margin: 0;
            padding: 0 10px 0 0;
          }
        }
        .ant-tabs-tab-active {
          --ant-tabs-ink-bar-color: white !important;
          --ant-tabs-item-selected-color: white !important;
          --ant-tabs-tab-active-color: white !important;
          .ant-tabs-tab-icon {
            color: white !important;
          }
          .ant-tabs-tab-btn {
            color: white !important;
          }
          border-color: #fff !important;
        }
      }
      position: relative;
      .ant-radio-button-wrapper {
        width: 135px;
        height: 52px;
        padding: 8px 0;
        background: none;
        border: none;
        border-bottom: 1px solid var(--blings_teal);
        border-radius: 0;
        position: unset !important;
      }
      .ant-radio-button-wrapper-checked {
        // background: var(--blings_teal);
        color: white;
        border-bottom: 1px solid white !important;
      }
    }
  }
}

.publish-btn-disabled {
  opacity: 0.8;
  cursor: pointer !important;
  :hover {
    cursor: pointer !important;
  }
}

.ant-popover {
  .ant-popover-arrow:after,
  .ant-popover-arrow:before {
    background-color: var(--blings_teal);
  }

  .ant-popover-inner {
    background-color: var(--blings_teal);

    .ant-popover-title {
      color: white;
      border-bottom: 1px solid var(--blings_that_gray);
    }

    .view-live-version-select {
      min-width: 150px;

      .ant-select-selector {
        background: var(--blings_teal);
        margin-right: 1rem;
      }
    }
    .ant-btn-default {
      background-color: transparent;
      color: var(--ant-color-text);
      border: 1.2px solid var(--blings_primary);
    }
  }

  select,
  .ant-select .ant-select-selector,
  .ant-select-selection-item-remove {
    color: #fff;

    .anticon {
      color: white;
    }
  }

  .ant-tag,
  .ant-select-multiple .ant-select-selection-item {
    background-color: var(--blings_that_gray);
    border-color: transparent;

    &:hover:not(.selected) {
      background-color: var(--blings_teal_60);
    }

    &.selected {
      //background-color: var(--blings_teal);
      border: 1px solid var(--blings_primary);
    }
  }

  .ant-select-clear {
    background-color: transparent;
  }
}
