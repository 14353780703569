.main-layout-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-color: #1a1a1a;
}
.nav-side-bar {
  display: flex;
  width: 50px;
  margin: 10px 0 0 10px;
}
.layers-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.flow-content {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.layers-main-wrapper {
  display: flex;
  position: relative;
  border-radius: 18px;
  background: #262626;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content-element-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  background: #1a1a1a;
}

.scenes-tabs-wrapper {
  // overflow: hidden;
  width: 100%;
  background: #1a1a1a;
}
