.BlingsButton {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: var(--blings_pink);
  padding: 0.4rem 1.2rem;
  outline: none;
  border: none;
  border-radius: 9px;
  // font-size: 14px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  color: white;
  &:hover {
    background-color: var(--blings_pink_hover);
  }
  &.Disabled {
    background-color: #ff154d40;
    cursor: not-allowed;
    pointer-events: none;
  }
  &.Outlined {
    font-weight: 400;
    border: 1.2px solid var(--blings_pink);
    background-color: white;
    color: var(--blings_pink);
    &:hover {
      background-color: rgba(255, 21, 77, 0.1);
      color: var(--blings_pink);
      border: 1.2px solid var(--blings_pink);
    }
  }
}
