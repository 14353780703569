.save-flow-btn:not(:disabled) {
  border: 1px solid #4f4f4f;
  border-radius: 7px;
  color: red !important;
  background: #1e1e1e !important;
}
.save-flow-btn:disabled {
  border: 1px solid #4f4f4f;
  border-radius: 7px;
  color: #bebebe;
  background: #1e1e1e !important;
}
.add-flow {
  border: 1px solid #4f4f4f;
  border-radius: 7px;
  color: #bebebe;
}
.flows-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #262626;
}
.player-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #262626;
  padding: 20px;
  border-bottom: 1px solid #1e1e1e;
  border-radius: 10px 10px 0 0;
}
.bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 57px;
  width: 100%;
  background: #262626;
  border-top: 2px solid #1a1a1a;
  border-bottom: 3px solid #1a1a1a;
}

.scenses-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 300px;
  min-height: 150px;
  background: #262626;
  overflow: hidden;
  // border: 1px solid #1e1e1e;
  border-radius: 0 0 10px 10px;
  bottom: 0;
  ::-webkit-scrollbar-track {
    margin-left: 230px !important;
  }
}
.scenses-list {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  background: #1e1e1e;
  overflow: auto;
}

.empty-player-container {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 90%;
  background: #262626;
  aspect-ratio: 09/16;
  padding: 20px;
}
.flow-vid-wrapper {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.mute-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  :hover {
    cursor: pointer;
  }
}

.mute-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }

  .mute-btn {
    font-size: 24px;
    border: 2px solid gray;
    border-radius: 50%;
    padding: 0.5rem;
    z-index: 1; // Ensure the icon appears above the line
  }

  .mute-line {
    content: "";
    height: 2px;
    width: 100%;
    background-color: gray;
    position: absolute; // This is only for the line, not the icon
    top: 50%;
    transform: translateY(-50%);
    rotate: 135deg;
    z-index: 1; // Ensure the line is behind the icon
  }
}
.vid-bottom-ctrls {
  height: fit-content;
  padding: 1rem;
  display: flex;
  gap: 5rem !important;
  justify-content: center;
  .ant-btn {
    z-index: 21;
  }
}
#loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
