.variant-row {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.experiment-viewport {
  width: 100%;
  padding: 10px;
}

.experiment-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  .upload-textarea-container {
    width: inherit;
    margin-top: 0.5rem;
  }
}

.variant-option-title {
  margin-right: 0.5rem;
  margin-bottom: 8px;
}

.variant-option-title-delete {
  margin: 0 0 0 8px;
  cursor: pointer;
}
.initial-add-option {
  display: block;
  width: 100%;
}
.variant-add-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  .add-variant-text {
    margin-left: 0.3rem;
  }
  .ai-icon {
    margin-left: auto;
  }
}

.variant-add-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 116px;
}

.experiment-option-add-div {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.variant-option-add-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 116px;
}
.variant-add-option {
  margin: 0;
  margin-bottom: 8px;
  margin-right: 0.5rem;
  cursor: pointer;
  text-align: center;
}
