.SchemaEditors {
  overflow: hidden;
  pointer-events: none;
  > * {
    pointer-events: auto;
  }

  .SchemaEditors-open-button {
    display: flex;
    justify-content: flex-end;
  }

  .ant-drawer-body {
    padding: 0;
  }
  .tabs {
    background-color: var(--blings_that_gray);

    > .ant-tabs-nav {
      .InfoTooltip {
        display: inline-block;
      }
    }

    > div.ant-tabs-nav
      > div.ant-tabs-nav-wrap
      > div
      > div.ant-tabs-tab.ant-tabs-tab-active {
      background-color: var(--blings_that_gray);
      border-bottom: none;
      border-radius: 0;
      border: none;
      .ant-tabs-tab-btn {
        border-bottom: 2px solid var(--blings_primary);
        .InfoTooltip {
          position: absolute;
        }
      }
      div {
        color: white;
      }
    }
    > div.ant-tabs-nav > div.ant-tabs-nav-wrap > div > div.ant-tabs-tab {
      margin: 0;
    }

    .ant-tabs-content-holder {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .ant-tabs-nav-list {
    flex: 1;
    .ant-tabs-tab {
      margin: 0;
      justify-content: center;
    }
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background-color: var(--blings_teal);
    border: none;
    /* margin: 0; */
    flex: 1;
    div {
      color: white;
    }
  }

  .ant-drawer-content {
    overflow-x: hidden;
    background-color: transparent;
    .ant-drawer-header {
      background-color: var(--blings_pink);
      color: white;
      height: 32px;
      min-height: 32px;
      padding: 0;
      .ant-drawer-header-title {
        justify-content: center;
      }
    }
  }

  .dynamic-form-header {
    > .ant-btn-primary {
      margin-left: 10px;
    }
  }

  $footerHeight: 3rem;
  .editor-wrapper {
    padding-bottom: $footerHeight;
  }
  .footer {
    //filter: $shadow;
    //box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);

    height: $footerHeight;
    // position: absolute;
    bottom: 0;
    width: 100%;
    //padding: 0.7rem 0 ;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .save-schema-btn {
      margin-bottom: 1.7rem;
    }
  }
}
