.upload-textarea-container {
  width: 400px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.custom-textarea {
  padding-right: 3rem;
  &:focus {
    outline: none;
  }
}

.upload-button {
  position: absolute;
  bottom: 28px;
  right: 8px;
  cursor: pointer;
}

.upload-icon-container {
  position: absolute;
  top: 100%;
  right: 10px;
  z-index: 1;
  cursor: pointer;
}

.upload-icon {
  font-size: 18px;
}
