.VidContainerWrapper {
  max-height: 90vh;
  height: 100%;
  padding: 1rem;
  padding-top: 3rem;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  align-items: center;

  .VidContainerWrapper-inner-container {
    overflow: auto;
    overflow-y: hidden;
    max-width: fit-content;
    resize: horizontal;
    min-width: 15rem;
    &::-webkit-resizer {
      cursor: nesw-resize;
      align-items: left;
      border-left: 1.4em solid transparent;
      border-bottom: 1.4em solid #ea2f5794;
    }
  }
  .vid-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row;
  }
  .VidContainerWrapper-inner-container2 {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .Vid-Bottom-Ctrls {
    height: fit-content;
    padding: 1rem 0;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    max-width: 500px;
    .ant-btn {
      z-index: 21;
    }
  }
  #loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mute-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  :hover {
    cursor: pointer;
  }
}

.mute-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }

  .mute-btn {
    font-size: 24px;
    border: 2px solid gray;
    border-radius: 50%;
    padding: 0.5rem;
    z-index: 1; // Ensure the icon appears above the line
  }

  .mute-line {
    content: "";
    height: 2px;
    width: 100%;
    background-color: gray;
    position: absolute; // This is only for the line, not the icon
    top: 50%;
    transform: translateY(-50%);
    rotate: 135deg;
    z-index: 1; // Ensure the line is behind the icon
  }
}
