$font-weight: 400;
$background-color: #262626;
$border-color: #efefef;
$text-color: #bebebe;
$handle-color: rgba(0, 0, 0, 0.25);
$box-shadow-border: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05);
$box-shadow-common: 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
$box-shadow: $box-shadow-border, $box-shadow-common;
$focused-outline-color: #4c9ffe;
$dragging-opacity: 0.5;

.add-scene-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: sticky;
  margin-left: auto;
  right: 0;
  background: #1a1a1a;
  height: 100%;
  // border-left: 1px solid #424242;
}

.active-scene-item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .scene-name {
    color: #fcfcfc !important;
  }
}

.header-menu-item {
  width: 100%;
  height: 100%;
  display: flex;
}
.header-menu-item-disabled {
  color: #918e8e;
  pointer-events: none !important;
}

.flowsTable {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2px; /* Optional spacing between columns */
  row-gap: 3px;
  overflow-x: auto;
  background-color: #1a1a1a;
  padding-bottom: 3px;
}

.input-flow-name {
  color: white;
  width: 150px;
  height: 32px;
  border: 1px solid #434343 !important;
  border-radius: 4px;
  padding: 0 10px;
  outline-color: #ff154d;
  outline-style: solid;
}

.flowRow {
  display: contents;
  &:hover:not(.activeFlow) {
    .scenesList:not(.active-flow),
    .Item:not(.tag-item),
    .sortable-wrapper,
    .stickyColumn .Header:not(.active-header),
    .Wrapper:not(.active-header) {
      background-color: #2d2d2d !important;
    }
  }
}

.scenes-list-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.stickyColumn {
  position: sticky;
  left: 0;
  background: #262626;
  z-index: 1;
  border-right: 1px solid #1e1e1e;
  &:hover {
    .Header:not(.active-header) {
      background-color: #2d2d2d !important;
    }
  }
}

.flow-diagram-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 2px;
  background: #1e1e1e;
  outline: none;
}
.no-flows {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  color: #bebebe;
}

.scenesList {
  display: flex;
  // background-color: #262626;
  height: 44px;
  .active-flow {
    background-color: #1a1a1a !important;
  }
  &:hover {
    .Header:not(.active-header) {
      background-color: #2d2d2d !important;
    }
    .sortable-wrapper:not(.active-flow) {
      .Item:not(.tag-item) {
        background-color: #2d2d2d !important;
      }
      background-color: #2d2d2d !important;
    }
    .single-flow-container {
      background-color: #2d2d2d !important;
    }
  }
}

.activeFlow {
  background-color: #f0f0f0; /* Optional: highlight active flow */
}

.sortable-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  gap: 10px;
  width: 100%;
  background-color: #262626;
  padding-left: 10px;
}

.content-wrapper {
  max-width: 130px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: middle;
  overflow: hidden;
}

.scene-name {
  display: inline-block;
  position: relative;
  text-overflow: clip;
  &:hover {
    animation: scroll-rtl 3s linear forwards;
  }
}
@keyframes scroll-rtl {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-100%);
  }
}

.edit-flow-name-btn {
  padding: 0;
  display: none;
}
.single-flow-container {
  // display: flex;
  // flex-direction: row;
  // width: 100%;
  // height: 44px;
  // align-items: center;
  // background-color: #262626;

  &:hover {
    background-color: #2d2d2d;
    .Header:not(.active-header),
    .Item {
      background-color: #2d2d2d !important;
    }
  }
}
.active-flow {
  background-color: #1e1e1e !important;
}

.scene-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 70px;
  background: #df0808;
  border-bottom: 1px solid #262626;
}

.ant-btn.remove-scene-btn {
  color: #df0808;
  background-color: #4c9ffe !important;
  padding: 0;
  &:hover {
    color: #df0808;
  }
}
.add-scene-menu-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: flex;
  height: 100%;
}
.add-scene-menu-item-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Header {
  display: flex;
  padding: 3px 7px 3px 0;
  align-items: center;
  width: 225px;
  height: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: space-between;
  background-color: #262626;
  border-radius: 0;

  .Actions {
    opacity: 0;
    width: 30px;

    > *:first-child:not(:last-child) {
      opacity: 0;

      &:focus-visible {
        opacity: 1;
      }
    }
  }

  &:hover {
    .Actions {
      opacity: 1 !important;
    }
    .Actions > * {
      opacity: 1 !important;
    }
    .edit-flow-name-btn {
      display: flex;
    }
  }
}
.Label {
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.active {
  background: none;

  .tag-item {
    height: 38px !important;
    span {
      color: #fcfcfc !important;
      font-size: 12px;
    }
  }
}
.tag-item {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  width: 180px;

  border: 2px solid #424242;
  padding-left: 0 !important;
  border-radius: 8px !important;

  box-shadow: $box-shadow;
}
.Item {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  height: 44px;
  padding: 0 8px;
  background-color: $background-color;
  box-shadow: none;
  outline: none;
  box-sizing: border-box;
  list-style: none;
  transform-origin: 50% 50%;

  -webkit-tap-highlight-color: transparent;

  color: $text-color;
  font-weight: $font-weight;
  font-size: 12px;
  white-space: nowrap;

  transform: scale(var(--scale, 1));
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  &:focus-visible {
    box-shadow: 0 0px 4px 1px $focused-outline-color, $box-shadow;
  }

  &:not(.withHandle) {
    touch-action: manipulation;
    cursor: grab;
  }

  &.dragging:not(.dragOverlay) {
    z-index: 0;
    background-color: #1e1e1e;
    opacity: 0.1 !important;
    &:focus {
      box-shadow: $box-shadow;
    }
  }

  &.disabled {
    color: #999;
    background-color: #f1f1f1;
    &:focus {
      box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1), $box-shadow;
    }
    cursor: not-allowed;
  }

  &.dragOverlay {
    cursor: inherit;
    animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
    opacity: 1 !important;
  }

  &.color:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 100%;
    width: 3px;
    display: block;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: var(--color);
  }

  &:hover {
    .Remove {
      visibility: visible;
    }
  }
}

.Remove {
  visibility: hidden;
}

.Actions {
  display: flex;
  align-self: flex-start;
  margin-top: -12px;
  margin-left: auto;
  margin-bottom: -15px;
  margin-right: -10px;
}

@keyframes pop {
  0% {
    transform: scale(1);
    box-shadow: var(--box-shadow);
  }
  100% {
    transform: scale(var(--scale));
    box-shadow: var(--box-shadow-picked-up);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Wrapper {
  width: 180px;
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
    scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;

  &.fadeIn {
    animation: fadeIn 500ms ease;
  }

  &.dragOverlay {
    --scale: 1.1;
    --box-shadow: $box-shadow;
    --box-shadow-picked-up: $box-shadow-border,
      -1px 0 15px 0 rgba(34, 33, 81, 0.01),
      0px 15px 15px 0 rgba(34, 33, 81, 0.25);
    z-index: 999;
  }
}
