.download-btn {
  color: #b6b6b6;
  &:hover {
    color: #fff;
  }
}
.burger-overlay-menu {
  display: flex;
  flex-direction: column;
  .text-buttons {
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
  }
  > * {
    cursor: pointer;
  }
  .menu-option {
    color: var(--ant-color-text);
    &:hover {
      color: white;
    }
  }
  background-color: var(--blings_that_gray);
  align-items: center;
  border-radius: 4px;
  padding: 1rem;
  .log-out {
    margin-top: 0.8rem;
    button {
      background-color: transparent;
      color: var(--ant-color-text);
      border: 1.2px solid var(--blings_primary);
      &:hover {
        background-color: rgba(255, 21, 77, 0.1);
        color: white !important;
      }
    }
  }
}
