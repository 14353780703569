.project-galery-container {
  padding: 50px 125px;
  height: 100%;
}

.project-gallery-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  color: white;
}
.mt-8-center {
  margin-top: 8rem;
  text-align: -webkit-center;
}
