$border-disabled: #53545c7e;

.ModsList-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  > .ant-space-item:last-of-type {
    overflow: hidden;
  }
}

.pencilEditName {
  display: flex;

  // stroke: white;
  svg {
    &:hover {
    }
  }
}
.dynamic-scene-select {
  .ant-select-selection-item {
    border: 1px solid #53545c !important;
  }
}

.modsList-collapse-container {
  //overflow: auto;
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 0.5rem;

  .ant-collapse-header,
  .ant-collapse-item {
  }

  &,
  .ModsList.ant-collapse,
  .ant-collapse-item,
  .ant-collapse-header,
  .modPanelText,
  .modPanelText2,
  .ant-collapse-content {
    color: var(--ant-color-text);
  }

  .extra-buttons {
    display: flex;
    gap: 0.7rem;
    align-items: center;
  }

  .modPanelText2 {
    display: flex;
    align-items: center;
    color: var(--ant-color-text);
    justify-content: start;

    .anticon {
      font-size: 0.9rem;
      margin-right: 0.3rem;
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      .modPanelText,
      .modPanelText2,
      .modPanelBtns {
        // color: #2c2d33 !important;
      }
    }
  }

  .ModsList-container {
    height: 100%;
    width: 100%;

    > .ant-space-item:last-of-type {
      overflow: hidden;
    }
  }
}

.modPanelText2 {
  #header-text-color {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ModsList {
  --ant-color-text-heading: #bebebe;
  background: none;
  border: none;

  overflow-x: hidden;
  width: 100%;

  .modIconTitleWrapper {
    flex: 0 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 1.5rem;
    text-align: center;
    white-space: nowrap;

    .ant-select-selector {
      max-width: 100%;
    }

    .modTitle {
      // font-size: 50%;
      font-weight: bold;
    }

    .modIcon {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      > .anticon {
        height: 60%;

        > svg {
          height: 100%;
        }
      }
    }

    .modIcon.hasError {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 0;
    }
  }

  .ant-collapse-content {
    border: none;
  }

  //.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header
  .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding: 0;

    .ModsListPanelHeader {
      padding-left: 0px;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-radius: 0;
    }

    .ant-collapse-arrow {
      //left: 7px;
      display: none;
    }
  }

  .ant-collapse-header {
    //transition: unset !important;
  }

  .ant-collapse-header {
    // border-left-color: transparent !important;
  }

  .draggable-superset-icon {
    width: 10px;
    margin-left: 7px;
    margin-right: 5px;

    .draggable-icon {
      display: none;
    }

    &.isActive {
      visibility: hidden;
    }
  }

  .ant-collapse-content,
  .ant-collapse-content-box,
  .ant-collapse-item-active .ant-collapse-header,
  .ant-collapse-header:hover {
    // border-left-color: inherit !important; // so top level JS can set color
    .draggable-icon {
      display: block;
    }
  }

  //.ant-collapse-item {
  .ant-collapse-content-box,
  .ant-collapse-header {
    // border-left: 6px solid;
    padding-left: 10px;
  }

  //}

  .modPanelText,
  .modPanelText2 {
    // font-size: 75%;
    //font-style: italic;
    flex: 1 1 0%;
    margin: 0px; // 0.3rem;f

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .modPanelText {
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ModForm {
    .ant-collapse-content > .ant-collapse-content-box > .ant-space-vertical {
      max-width: 100%;
    }
  }

  .ModForm.disabled {
    .modIconTitleWrapper,
    .title-texts,
    .modPanelText2,
    .mod-main-content,
    .ant-collapse-content-box {
      opacity: 0.6;
      filter: saturate(0.4);
    }
  }

  .ModForm {
    > .ant-collapse-header > span:first-child {
      width: 100%;
    }

    .ant-collapse-header:hover {
      .modPanelBtn {
        opacity: 1;
        &:hover {
          color: white;
        }
      }
    }
  }

  .mod-icon-new {
    color: #1e1e1e;
    display: flex;
    margin-right: 0.2rem;
  }

  .ModsListPanelHeader {
    height: 2rem;

    // font-size: 0.9rem;
    .modEditConnectorName {
      border: none !important;
      color: var(--ant-color-text);
      border-bottom: 1px solid #d9d9d9 !important;
      outline: none !important;
      background: inherit !important;
      padding: 0 !important;
      margin: 0 !important;
      line-height: 0;
      box-shadow: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    .modIcon.editNameIcon {
      width: 1rem;
      height: 1rem;
      display: flex;
      // font-size: 12px;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .modEditConnectorName::-webkit-input-placeholder {
      /* WebKit browsers */
      font-weight: 200;
      color: #fff;
    }

    .modEditConnectorName:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-weight: 200;
      color: #fff;
    }

    .modEditConnectorName::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-weight: 200;
      color: #fff;
    }

    .modEditConnectorName:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      font-weight: 200;
      color: #fff;
    }

    .title-texts {
      width: 8.5rem;
    }

    .modPanelBtns {
      display: flex;
      position: absolute;
      right: 1rem;
      flex-direction: row;
      width: 12rem;
    }

    .modPanelBtn {
      color: var(--ant-color-text);
      opacity: 0.5;
      display: flex;
      gap: 0.3rem;

      &:hover {
        opacity: 1;
      }
    }
  }

  .panel-icon {
    display: flex;

    &:not(.warning-icon) {
      margin-left: 0.3rem;
    }
  }

  .modPanelText {
    display: flex;
  }

  .ant-collapse-item-active {
    .modPanelBtn,
    .pencilEditName,
    .modIcon.editNameIcon,
    .modEditConnectorName,
    .modPanelText,
    .panel-icon {
      // color: #2c2d33;
    }

    .modEditConnectorName {
      // border-bottom: 1px solid #2c2d33 !important;

      &::-webkit-input-placeholder {
        // color: #2c2d33 !important;
      }

      &:-moz-placeholder {
        // color: #2c2d33;
      }

      &::-moz-placeholder {
        // color: #2c2d33;
      }

      &::-ms-input-placeholder {
        // color: #2c2d33;
      }

      .draggable-icon {
        color: #5e6366;
      }
    }
  }

  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 10px;
  }

  .ant-collapse-content {
    background: var(--blings_that_gray);
  }

  .ant-collapse-item {
    &.ModForm {
      background: transparent;
      margin-bottom: 0.8rem;
      border-radius: 10px;
      border: 1px solid var(--ant-color-text);

      &.disabled {
        border: 1px solid $border-disabled;
      }
    }
  }

  border-radius: 10px;

  @mixin ant-collapse-styles($color-variable) {
    &.ant-collapse-item-active {
      border: 1px solid var(--#{$color-variable});

      &.disabled {
        border: 1px solid $border-disabled;
      }
    }

    --my-dynamic-color: var(--#{$color-variable});

    .warning-icon {
      color: #2c2d33;
    }

    .ant-collapse-content-active {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top: none;
    }

    .advanced-collapse {
    }

    &.ant-collapse-item-active.ant-collapse-no-arrow > .ant-collapse-header {
      border-bottom: 1px solid #53545c;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 10px;
    }

    &.disabled {
      --my-dynamic-color: #434343;

      .ant-collapse-content-active,
      &.ant-collapse-item-active.ant-collapse-no-arrow > .ant-collapse-header {
        border-color: $border-disabled;
      }
    }
  }

  // Apply mixin to groups
  .interactiveOpen,
  .interactiveGoto,
  .interactiveConcat,
  .interactiveJSExpression {
    @include ant-collapse-styles(blings_connectors_interactive);
  }

  .text {
    @include ant-collapse-styles(blings_connectors_text);
  }

  .color,
  .themeColor {
    @include ant-collapse-styles(blings_connectors_color);
  }

  .video,
  .audio,
  .asset {
    @include ant-collapse-styles(blings_connectors_mediaAsset);
  }

  .advancedConfig,
  .custom,
  .transform,
  .onPlayerEvent {
    @include ant-collapse-styles(blings_connectors_advanced);
  }

  .hideLayer,
  .countdown,
  .transitionTiming {
    @include ant-collapse-styles(blings_connectors_utils);
  }

  .interactiveInput,
  .interactiveForm {
    @include ant-collapse-styles(blings_connectors_input);
  }
}

.ant-select-dropdown,
.ant-select-dropdown .ant-select-item-option-content {
  // font-size: 0.7rem;
}

.expose-to-platform {
  .expose-to-platform-checkbox {
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
