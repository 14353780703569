.JsEditor {
  .OpenWindow {
    cursor: pointer;
  }
}

.JsEditorModal {
  .ant-modal-content {
    background-color: var(--blings_teal);
    .line-numbers {
      width: 15px !important;
    }
  }
  .ant-modal-footer {
    border: none;
  }
}
