.group-button-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 36px;
}

.btn-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  transition: color 0.2s ease-in-out;
  background: #1a1a1a;
  border: none;
  padding: 0;
  width: 36px;
  height: 36px;

  .btn-title {
    padding-left: 10px;
    color: #bebebe;
  }
}

.btn-title {
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: #bebebe;
}

.btn-unselected {
  color: #888888;
  pointer-events: auto;
  cursor: pointer;
  .layers-icon {
    stroke: #6e7079;
    fill: none;
  }
}

.btn-unselected:hover {
  color: #e5e7eb;
  border: none;
  background: none;
  border: 1px solid #4c5667;
  border-radius: 6px;
  .layers-icon {
    stroke: #e5e7eb;
    fill: none;
  }
}

.btn-selected {
  color: #bebebe;
  pointer-events: none;
  cursor: default;
  background: #262626;
  border: 1px solid #4c5667;
  border-radius: 6px;

  .layers-icon {
    stroke: none !important;
    fill: #bebebe !important;
  }
  stroke-width: 1.5px;
}
.inner-btn {
  width: 36px;
  height: 36px;
}

.btn-icon {
  margin-right: 0.5rem;
}

.bottom-border {
  bottom: 0;
  height: 0.125rem;
  background: #bebebe;
  transition: all 0.3s ease-in-out;
}
