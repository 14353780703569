@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700&family=Open+Sans:wght@500&display=swap");
html {
  padding: 0;
  margin: 0;
}

body {
  --ant-color-text: #bebebe;
  //font-family:  'Source Sans Pro', sans-serif;
  //font-family: 'Nunito', sans-serif;
  //font-family: 'Open Sans', sans-serif;
  font-family: "Inter", sans-serif;
  //letter-spacing: ;
  letter-spacing: 0.04em;
  padding: 0;
  margin: 0;
  font-feature-settings: "tnum", "tnum";
  background-color: var(--blings_that_gray);
  --ant-border-radius-lg: 10px;
  // font-size: 0.7rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 100vh;
}
.main {
  flex: 1;
  overflow: hidden;
  margin: 0 10px;
  padding: 10px 0;
  background: #1a1a1a;
}
.main,
.NoProjectSelectedScreen {
  background-color: var(--blings_teal);
}
._main-buttons {
  button {
    border-radius: 7px;
  }
}
#root {
  height: 100vh;
  .playground-left-sidebar {
    width: 28rem;
  }
  .playground-left-sidebar,
  .playground-top-bar.ant-layout-header {
    filter: none;
  }
}
.textbutton {
  color: var(--blings_that_gray);
  &:hover {
    color: var(--blings_primary_hover);
    text-decoration: underline;
  }
}

.form-collapse {
  width: 100%;
}
.form-container {
  overflow-y: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.pg-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex-grow: 1;
  order: 1;
  position: fixed;
}

.card {
  width: 500px;
}
.ant-cascader-menu-item-expand {
  .ant-cascader-checkbox {
    display: none;
  }
}
.ant-cascader-menu {
  overflow-x: hidden !important;
}
.ant-cascader-menu-item {
  width: 345px;

  .ant-cascader-menu-item-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.subSelector {
  .ant-cascader-menu-item {
    width: 230px;
  }
}

//.main{
$dataSetterHeight: 25vh;

#vid-container {
  //flex-grow: 2;
  //order: 2;
  //height: (100 - $dataSetterHeight);
  /*position: fixed;*/
  /*width: 500px;*/
}
.jsoneditor {
  height: $dataSetterHeight;
}

.ant-btn-primary.look-like-enable {
  &:disabled {
    opacity: 1;
  }
}

.Header {
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
}
.ant-switch {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  .ant-switch-inner {
    background-color: #5c5c5c;
  }
  &.ant-switch-checked {
    .ant-switch-inner {
      background-color: var(--blings_primary);
    }
  }
}

.code-box-demo .ant-switch {
  margin-bottom: 8px;
}

[data-theme="compact"] p,
p {
  margin: 0;
}
.ant-picker-dropdown {
  --ant-color-text: black;
  --ant-color-text-heading: black;
  color: black;
}
.config-panel {
  max-height: 80vh;
  overflow: scroll;
  padding-bottom: 20px;
}

.SideFormActionButtons {
  padding: 0.5rem;
  display: flex;
  align-items: flex-end;
  height: 100%;
  justify-content: center;
  //background: #d8d6d6;

  .ant-btn-primary {
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 6px;
    &:active,
    &:focus {
      &.secondary {
        background: transparent;
      }
      border-color: #4f4f4f;
    }
  }
}
.ant-btn {
  border-radius: 7px;
}

.ant-tooltip {
  .ant-tooltip-inner {
    background: var(--blings_purple);
    opacity: 0.9;
  }
  .ant-tooltip-arrow:after,
  .ant-tooltip-arrow:before {
    background: var(--blings_purple);
  }
  ::placeholder {
    color: #ffffff80;
    opacity: 1;
  }
}

::selection {
  background-color: rgba(179, 212, 252, 0.56);
  color: #fff;
  text-shadow: none;
  //background: #ea2f5759;
  //background: var(--blings_primary)99;
}
.notFoundContent {
  color: #fff;
  opacity: 0.3;
  min-height: 2rem;
  padding: 1rem;
  text-align: center;
}
.ant-modal-root {
  .popup-text {
    color: black;
  }
  .blings-confirm-modal {
    .ant-modal-confirm-btns .blings-btn {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background-color: var(--blings_pink);
      padding: 0.4rem 1.2rem;
      outline: none;
      border: none;
      border-radius: 9px;
      font-size: 12px;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      color: white;
      &:hover {
        background-color: #ff3d64 !important;
        color: white !important;
      }
      &.Disabled {
        background-color: #ff154d40;
        cursor: not-allowed;
        pointer-events: none;
      }
      &.Outlined {
        font-weight: 400;
        border: 1.2px solid var(--blings_pink);
        background-color: white;
        color: var(--blings_pink);
        &:hover {
          background-color: rgba(255, 21, 77, 0.1);
          color: var(--blings_pink);
          border: 1.2px solid var(--blings_pink);
        }
      }
    }
  }
}

#root .playground-left-sidebar,
#root .playground-top-bar,
.main,
#root .NoProjectSelectedScreen {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    //user-select: none;
  }

  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6,
  // div:not(.ant-collapse-item-active):not(.ant-picker-cell-inner):not(.modPanelBtn):not(.editNameIcon):not(.ModForm) *:not(.ModForm),
  // span:not(.anticon[role="button"]):not([class^="mtk"]):not(.ant-collapse-item-active):not([class^="mtk"]) {
  //   color: #e1e2e9;
  // }
  .ModForm,
  .interactiveConcat {
    .ant-select-selectionitem {
    }
  }
  .anticon:not(.anticon-delete) {
    color: var(--ant-color-text);
  }
  .mr5 {
    margin-right: 0.5rem;
  }
  .ant-typography.ant-typography-secondary {
    //color: var(--blings_blue);
    color: #fff;
    opacity: 0.5;
  }
  input:not(.project-search-input), select, .ant-select .ant-select-selector,
  //.ant-radio-button-wrapper,
  textarea,
  .ant-input-number,.ant-input-affix-wrapper:not(.project-search-input),
  .ant-input-group-addon, .ant-input-number-handler-wrap {
    background-color: var(--blings_teal);
    // border-color: #53545c;
    border: none;
    .ant-select-selection-wrap {
      width: 100%;
      .ant-select-selection-overflow-item {
        .ant-select-selection-item-content {
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .FrameIndicator {
    .ant-input {
      background-color: var(--blings_that_gray);
    }
  }
  .editor-wrapper {
    .ant-input,
    .ant-input-number-input {
      background-color: var(--blings_teal);
    }
  }

  .ant-input-number-handler {
    border-color: var(--blings_that_gray);
  }
  .ant-input-group-addon,
  .ant-input-number-handler-wrap {
    // background-color: var(--blings_teal);
    color: var(--ant-color-text);
    border-right: 2px solid var(--blings_that_gray);
  }
  .ant-upload button {
    padding: 0 0.5rem;
    border-color: var(--blings_teal_60);
    background-color: var(--blings_teal);
  }
  .ant-input-suffix .ant-input-clear-icon {
    filter: invert(1);
  }
  .ant-select-clear {
    background: transparent;
  }
  .ant-select-selection-placeholder,
  ::placeholder {
    opacity: 0.5;
    color: rgb(255, 255, 255);
  }
  .ant-select-multiple .ant-select-selection-item {
    background-color: var(--blings_that_gray);
  }
  .ant-switch-checked:focus {
    box-shadow: none;
  }

  .ant-checkbox-inner {
    background-color: transparent;
    border-color: #5c5c5c;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border: none;
      background-color: var(--blings_primary);
      &::after {
        border-color: var(--blings_teal);
      }
    }
  }
  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      border: none;
      background-color: var(--blings_teal_80);
    }
  }
}

.ant-modal-footer {
  a {
    color: var(--blings_that_gray);
    &:hover {
      color: var(--blings_primary_hover);
      text-decoration: underline;
    }
  }
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--blings_primary_hover);
  border-color: var(--blings_primary_hover);
}
.ant-btn-primary:disabled {
  background-color: var(--blings_primary_disabled);
  border-color: var(--blings_primary_hover);
  color: white;
}
.advanced-collapse {
  .advanced-radio-option {
    display: flex;
    gap: 0.5rem;
  }

  .media-ending {
    margin-bottom: 1rem;
    border-left: 2px solid var(--blings_primary);
    padding-left: 9px;
  }
  .ant-collapse-item {
    background: transparent;
    .ant-collapse-header {
      padding-left: 0;
      font-weight: bold;
      border-left: none;
    }
    .ant-collapse-content-box {
      border-left: none;
      display: flex;
    }
  }
}
.JsEditorModal {
  .ant-modal-footer {
    .ant-btn-default {
      background-color: transparent;
      color: var(--ant-color-text);
      border: 1.2px solid var(--blings_primary);
    }
  }
}
.ant-select-dropdown,
.mod-form-json-tree-select-dropdown,
.mod-form-select-dropdown {
  background-color: var(--blings_teal);
  color: white;
  .ant-select-tree-node-selected {
  }

  .ant-select-item-group {
    color: rgba(255, 255, 255, 0.54);
  }
  .ant-select-item-option {
    color: white;
    .type-icon {
      font-weight: 300;
      img {
        margin-right: 0.3rem;
      }
    }
  }
  .ant-select-item-option {
    background: transparent;
    &:hover {
      background-color: var(--blings_teal_80);
    }
    &.ant-select-item-option-selected {
      background-color: #363636;
      // color: white;
    }
    &.ant-select-item-option-active {
      background-color: #363636;
    }
  }
  .ant-empty {
    color: white;
  }
  .ant-select-tree {
    background: transparent;
    color: white;
    .ant-select-tree-node-content-wrapper:hover {
      background-color: #262626;
      //opacity: 0.6;
    }
    .ant-select-tree-node-selected {
      background-color: #363636;
    }
  }
}
.ant-spin-dot-item {
  background-color: white;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  top: 0px;
  left: 0px;
  position: absolute;
  cursor: pointer;
  user-select: none;
  &-track {
    background-color: var(--blings_teal);
    // border-radius: $track-radius;
    border-radius: 5px;
  }
  &-thumb {
    background-color: var(--blings_teal_80);
    border-radius: 5px;
    width: 6px;
    // border-radius: 99px;
  }
}

.advanced-collapse.ant-radio-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .ant-radio-inner {
    background-color: #5e676c;
    border-color: #5e676c;
  }
  /* Styling for the radio button when it's checked */
  .ant-radio-checked .ant-radio-inner {
    border-color: #ff154d;
  }
  .ant-radio-checked .ant-radio-inner::after {
    background-color: #ff154d;
    border-radius: 50%;
    transform: none;
  }
}
.open-code-icon {
  color: rgba(255, 255, 255, 0.7);
  &:hover {
    color: white;
  }
}
.countdown-connector {
  .column-countdown {
    margin: 0.5rem 0px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .time-unit-selector {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    & > div:last-child {
      margin-left: auto;
    }
    &.isDisabled {
      opacity: 0.5;
      .ant-checkbox-wrapper {
        opacity: 1;
      }
    }
  }
  .add-timeUnit {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem 0 0.5rem 0;
  }
  .advanced-connector {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .sub-menu {
    margin-bottom: 0.5rem;
  }
  .sub-advanced-connectors {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    label {
      white-space: nowrap;
    }
  }
  #date-picker {
    width: 100%;
    .ant-picker {
      border: none;
      width: 100%;
      background-color: var(--blings_teal);
    }
  }
}
.ant-picker-dropdown {
  .ant-picker-header-next-btn,
  .ant-picker-header-super-next-btn,
  .ant-picker-header-prev-btn,
  .ant-picker-header-super-prev-btn {
    --ant-color-icon: #bebebe;
  }
  .ant-picker-time-panel-cell-selected {
    .ant-picker-time-panel-cell-inner {
      background: transparent !important;
      border: 1px solid #4f4f4f;
    }
  }
  .ant-picker-panel-container {
    border: 1px solid #4f4f4f;
    background-color: var(--blings_teal);
    --ant-color-text-disabled: #4f4f4f;
    --ant-color-text: #bebebe;
    --ant-color-text-heading: #bebebe;
    .ant-picker-cell-inner:hover {
      color: white;
    }
  }
}
.row-element {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sortable-wrapper::-webkit-scrollbar-track {
  background-color: forestgreen !important;
  margin: 2px !important;
}
.sortable-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-clip: content-box;
}
.sortable-wrapper::-webkit-scrollbar {
  padding: 5px !important;
  width: 18px !important;
  height: 18px !important;
}

.action-flow.ant-dropdown {
  ul::-webkit-scrollbar-track {
    background-color: transparent !important;
    margin: 2px !important;
  }
  ul::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-clip: content-box;
  }
  ul::-webkit-scrollbar {
    padding: 5px !important;
    width: 8px;
  }
}

.action-flow.ant-dropdown .ant-dropdown-menu {
  .header-menu-item-disabled {
    color: #3044da !important;
    background: #3044da !important;
  }
}
.action-flow.ant-dropdown .ant-dropdown-menu {
  background-color: #1e1e1e;
  color: #e1e2e9;
  border-radius: 8px;
  border: 1px solid #bebebe;
  max-height: 160px;
  overflow-y: auto !important;
  background-clip: border-box !important;

  .ant-dropdown-menu-item {
    height: 32px;
    color: #e1e2e9;
    padding: 4px 12px;
    &:hover {
      background-color: #363636;
    }
  }
}

.time-unit-dropdown-overlay {
  .time-unit-dropdown {
    width: 40%;
    background-color: var(--blings_that_gray);
    .ant-dropdown-menu-item {
      color: white;
      .type-icon {
        font-weight: 300;
        img {
          margin-right: 0.3rem;
        }
      }
      background: transparent;
      &:hover {
        background-color: var(--blings_teal_80);
      }
      &.ant-dropdown-menu-item-selected {
        // border-radius: 0;
        background-color: var(--blings_teal);
        color: white;
      }
      &:active {
        background-color: var(--blings_teal_60);
      }
    }
  }
}
.divider-dropdown {
  background-color: #53545c;
  width: 80%;
  height: 1px;
  margin: 8px auto 0 auto;
}
input::selection,
textarea::selection {
  background: #ffb7b7; /* Background color for selected text */
  color: #000; /* Text color for selected text */
}

.interactive-space {
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #d9d9d9;
  }
}
.ant-cascader-dropdown {
  .ant-cascader-menu:last-child {
    .ant-cascader-menu-item:not(:hover) {
      --ant-cascader-option-selected-bg: transparent;
    }
  }
  .ant-cascader-menu-item[aria-checked="true"] {
    .ant-cascader-menu-item-content {
      font-weight: bold;
    }
  }
  .ant-cascader-menu-item-expand-icon {
    .anticon {
      color: var(--ant-color-text);
    }
  }
  .ant-cascader-checkbox {
    & > .ant-cascader-checkbox-inner {
      background-color: transparent;
      border-color: #5c5c5c;
    }
  }
  .ant-cascader-checkbox-checked {
    .ant-cascader-checkbox-inner {
      border: none;
      background-color: var(--blings_primary);
      &::after {
        border-color: var(--blings_teal);
      }
    }
  }
}

.ant-cascader-menus {
  padding: 1px 1px 1px 0;
}
