.vid-part-tags-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.vid-part-tags-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome/Safari */
}

.video-part-tag {
  border: none;
  background: #1a1a1a;
  display: flex;
  align-items: center;
  padding: 0;
  height: 36px;
  width: 100%;
  min-width: 70px;
  box-sizing: border-box;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  --ant-font-size-sm: 12px;
  font-weight: normal;
  color: #888888;

  &:hover {
    color: #d9d9d9;
    .dropdown-button {
      opacity: 1;
    }
  }
  > div.ant-space {
    width: 100% !important;
    border: none;
    padding-left: 0.6rem;
    padding-right: 0.2rem;
  }
  &.next {
    border: none !important;
    > div.ant-space {
      border: none;
    }
  }

  &.selected {
    color: #d9d9d9;
    background: #262626 !important;
    border: none;
    border-radius: 6px 6px 0 0;

    .dropdown-button {
      opacity: 1;
    }
    > div.ant-space {
      border: none !important;
      border-color: #1f8792;
    }
  }

  .dropdown-button {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }

  .icon-transition {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    rotate: 180deg;
  }

  .rotate {
    transform: rotate(180deg);
  }

  .icon-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  .menu-icon {
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
  }

  .scene-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 220px;
  }
  .scene-title-container {
    padding: 0 4px;
  }
  .border-tag-space {
    display: flex;
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-right: 1px solid #424242;
  }
  .border-tag {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 16px;
    background-color: #424242;
  }
}

/* Buttons */
.scroll-btn-wrapper {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  position: sticky;
  background: #1a1a1a;
  z-index: 99;
}

.scroll-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.scroll-btn {
  background: #1e1e1e;
  color: white;
  border: none;
  width: 32px;
  height: 32px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
}

.scroll-container {
  position: relative;
  width: 100%;
  height: 36px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.scroll-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #888888;
  &:hover {
    color: #d9d9d9;
  }
  &.left {
    height: 36px;
    width: 42px;
    &.shadow {
      box-shadow: 0 0 12px 0px #ffffff1c;
    }
  }
  &.right {
    height: 36px;
    width: 36px;
    &.shadow {
      box-shadow: 0 0 12px 0px #ffffff1c;
    }
  }
  &.disabled {
    color: #5c5c5c;
    cursor: default;
  }
}
.right-container {
  width: 42px;
}
