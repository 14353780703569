@media screen and (min-width: 1650px) {
  .playground-left-sidebar {
    width: 30rem !important;
    flex: 30rem !important;
    max-width: 30rem !important;
    min-width: 30rem !important;
  }
}

.playground-left-sidebar {
  z-index: 2 !important;
  left: 0 !important;
  box-shadow: 0 0 0 1.8px #1a1a1a;
  margin: 8px !important;

  &.ant-layout-sider-collapsed {
    .ant-layout-sider-children {
      padding: 0;
    }
  }
  padding: 0.7rem;
  .playground-left-sidebar-inner-container {
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    > .ant-space-item:nth-child(3) {
      // mods list
      flex: 1;
      overflow-y: auto;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none;
    }

    > .ant-space-item:last-of-type {
    }
  }

  .delete-video-part-btn {
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    $size: 1rem;
    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--ant-tag-default-color);
    border: none;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .video-parts {
    .vid-part-tags-container {
      max-height: 8.7rem;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
    }
    .video-part-tag {
      border: 1px solid #4f4f4f;
      background: transparent;
      padding: 0.2rem 0.6rem;
      cursor: pointer;
      margin-bottom: 5px;
      --ant-font-size-sm: 12px;
      &:hover {
        background-color: #363636;

        color: white;
      }
      &.selected {
        border-color: var(--blings_primary);
        color: white;
      }

      .dropdown-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none;
        background: none;
        cursor: pointer;
      }

      .icon-transition {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease;
        rotate: 180deg;
      }

      .rotate {
        transform: rotate(180deg);
      }

      .icon-centered {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
      }

      .menu-icon {
        margin-right: 8px;
        display: inline-flex;
        align-items: center;
      }
    }
  }
}

.dropdown-menu .ant-dropdown-menu {
  background-color: #262626 !important;
  color: #e1e2e9;
  border: none;
}

.dropdown-menu .ant-dropdown-menu-item {
  color: #e1e2e9;
}

.dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: #363636 !important;
}

.icon-margin {
  margin-right: 8px;
}
