.DataSetter {
  position: relative;
  .DataSetter_input {
    padding-right: 5rem;
  }

  .DataSetter_button {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}

//@import "./styles.scss";
.editor-wrapper {
  padding-top: 1.3rem;
  background: var(--blings_that_gray);
  height: 100%;
  //height: 100vh;
  //width: 25rem;
  overflow-y: auto;

  .jsoneditor {
    width: 100%;
    //height: 100%;
    overflow: auto;
    border-bottom: 1px solid;
    // override default styling of JSONEditor
    div.jsoneditor-main {
      border: none;

      .menu {
        border: none !important;
        background-color: var(--blings_primary) !important;
        box-shadow: none;
        padding: 0;
        height: auto;
        $height: 40px;

        > button {
          //height: $height !important;
          width: 20px;
          &:disabled {
            //width: 18px;
          }
        }

        button.code-mode {
          $selected-height: 32px;
          $margin: ($height - $selected-height) / 2;

          &.selected {
            box-shadow: inset 1px 1px 2px #75521d;
            height: $selected-height !important;
            margin-top: $margin;
            margin-bottom: $margin;
          }
        }
      }

      .contents {
        //background: rgba(143, 142, 142, 0.1) !important;
        //min-height: 100px;
        .json-node {
          background: rgba(240, 53, 100, 0.05);
        }
      }
      button {
        border-radius: 0;
      }
    }
    .insert-button {
      background: var(--blings_primary) !important;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }

    .jsoneditor-modal .header {
      background-color: var(--blings_primary) !important;
    }

    .jsoneditor-modal .contents .actions button.primary {
      //@include generic-button;
    }
  }
  ._footer {
    background-color: transparent;
    button,
    .ant-btn-primary {
      background-color: transparent;
      border: 1px solid #4f4f4f;
      color: var(--ant-color-text);
      &:disabled {
        border: 1px solid #4f4f4f;
        background-color: #363636;
        color: #5c5c5c;
        span {
          color: #5c5c5c;
        }
      }
      &:hover:not(:disabled) {
        span {
          color: white;
        }
        opacity: 1;
      }
    }
  }
}
.__blings__dynamic-form {
  .BaseField .right {
    align-items: center;
  }
}
